<template>
  <div class="page">
    <div class="banner">
      <img src="./img/banner.png" />
    </div>
    <div class="main-title-box">
      <div class="title">自动报名:</div>
      <van-switch
        :disabled="disabled"
        @change="switchChange"
        v-model="checked"
        size="24px"
      />
      <div class="status">{{ checked ? "开启" : "关闭" }}</div>
      <div class="txt" @click="show = true">报名说明</div>
      <img src="./img/message.png" @click="show = true" />
    </div>
    <div class="signUpBox">
      <div class="label">报名状态:</div>
      <div class="status">
        <span class="blue" v-if="signUpStatus">当天已报名</span>
        <span class="grey" v-else>当天未报名</span>
      </div>
    </div>
    <div class="edit-box" v-if="!editFlag" @click="editFlag = true">
      <div class="edit-box-left">编辑报名信息</div>
      <div class="edit-box-right">
        <img src="./img/edit.png" />
      </div>
    </div>
    <!-- 编辑报名信息 -->
    <div class="editInfo" v-if="editFlag">
      <div class="inputBox" style="margin-top: 0">
        <div class="inputBox-left">家长姓名</div>
        <div
          :class="[
            'inputBox-right',
            [form.childrenInfoFormList.length == 1 ? 'pr-28' : 'pr-78'],
          ]"
        >
          <input
            v-model="form.parentsName"
            type="text"
            placeholder="请填写家长姓名"
          />
        </div>
      </div>
      <div class="inputBox">
        <div class="inputBox-left">家长电话</div>
        <div
          :class="[
            'inputBox-right',
            [form.childrenInfoFormList.length == 1 ? 'pr-28' : 'pr-78'],
          ]"
        >
          <input
            v-model="form.parentsPhone"
            type="text"
            placeholder="请填写家长电话"
          />
        </div>
      </div>
      <div v-for="(item, index) in form.childrenInfoFormList" :key="index">
        <div class="inputBox">
          <div class="inputBox-left">孩子姓名</div>
          <div
            :class="[
              'inputBox-right',
              [form.childrenInfoFormList.length == 1 ? 'pr-28' : 'pr-78'],
            ]"
          >
            <input
              v-model="item.childrenName"
              type="text"
              placeholder="请填写孩子姓名"
            />
          </div>
          <img
            v-if="index > 0"
            class="removeIcon"
            src="./img/remove.png"
            @click="removeItem(index)"
          />
        </div>
        <div class="inputBox">
          <div class="inputBox-left">孩子班级</div>
          <div
            :class="[
              'inputBox-right',
              [form.childrenInfoFormList.length == 1 ? 'pr-28' : 'pr-78'],
            ]"
          >
            <input
              v-model="item.childrenClass"
              type="text"
              placeholder="请填写孩子班级,例101"
            />
          </div>
          <img
            v-if="index > 0"
            class="removeIcon"
            src="./img/remove.png"
            @click="removeItem(index)"
          />
        </div>
      </div>
      <div class="inputBox" @click="addOption()">
        <div class="add-left">
          <img src="./img/add.png" />
        </div>
        <div class="add-right">添加更多学生信息</div>
      </div>
      <div class="submitBtn" @click="submit()">提交信息</div>
    </div>
    <!-- 查看信息 -->
    <div class="cheackInfo" v-if="!editFlag">
      <div class="item">
        <div class="item-left">家长姓名</div>
        <div class="item-right">{{ form.parentsName }}</div>
      </div>
      <div class="item">
        <div class="item-left">家长电话</div>
        <div class="item-right">{{ form.parentsPhone }}</div>
      </div>
      <div v-for="(item, index) in form.childrenInfoFormList" :key="index">
        <div class="item">
          <div class="item-left">孩子姓名</div>
          <div class="item-right">{{ item.childrenName }}</div>
        </div>
        <div class="item">
          <div class="item-left">孩子班级</div>
          <div class="item-right">{{ item.childrenClass }}</div>
        </div>
      </div>
    </div>
    <!-- 报名说明弹框 -->
    <v-dialog
      :showCancelButton="false"
      :showConfirmButton="false"
      v-model="show"
      :closeOnClickOverlay="true"
    >
      <div class="messageBox">
        <div class="title">报名说明</div>
        <div class="content">
          1、用户参与勤学专线下午场次的报名需要先填写家长和孩子的信息。<br />
          2、填写完信息后，每天中午12点整时，报名开关为开启状态的用户，自动完成班车报名；报名开关为关闭状态的用户，将不会进行当天下午班车的报名。<br />
          3、每天中午12:00~12:30为系统自动报名期，此时无法修改报名开关状态。<br />
          4、当天报名是否成功，可以在中午12:00后通过当日报名状态查看。
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { applyUrl, getLastOrderInfoUrl, changeStatusUrl } from "./api.js";
import { mapState } from "vuex";
import { gloabalCount, toRegister } from "@/utils/common";
export default {
  name: "studyExpertLine",
  data() {
    return {
      show: false,
      disabled: false,
      checked: true,
      signUpStatus: false,
      form: {
        parentsName: "",
        parentsPhone: "",
        id: "",
        childrenInfoFormList: [],
      },
      editFlag: false,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "houseId", "communityId"]),
  },
  async created() {
    this.getTime();
    if (await toRegister(this.$route.path, this.$route.query, "热门活动报名")) {
      await this.getLastOrderInfo();
    }
  },
  mounted() {
    gloabalCount("", 84, 1);
  },
  methods: {
    // 提交信息按钮
    submit() {
      if (this.form.parentsName == "") {
        this.$toast("请填写家长姓名！");
        return;
      }
      if (this.form.parentsPhone == "") {
        this.$toast("请填写家长电话号码！");
        return;
      }
      let flag = false;
      this.form.childrenInfoFormList.forEach((item) => {
        if (item.childrenName == "" || item.childrenClass == "") {
          flag = true;
        }
      });
      if (flag) {
        this.$toast("请完善孩子信息！");
        return;
      }
      let params = {
        id: this.form.id,
        parentsName: this.form.parentsName,
        parentsPhone: this.form.parentsPhone,
        userId: this.userId,
        status: Number(this.checked),
        tenantId: this.tenantId,
        childrenInfoFormList: this.form.childrenInfoFormList,
      };
      this.$axios.post(`${applyUrl}`, params).then((res) => {
        if (res.code == 200) {
          this.getLastOrderInfo();
        }
      });
    },
    // 获取上次提交信息
    async getLastOrderInfo() {
      let params = {
        userId: this.userId,
        houseId: this.houseId || this.communityId || undefined,
        tenantId: this.tenantId,
      };
      let res = await this.$axios.get(`${getLastOrderInfoUrl}`, {
        params: params,
      });
      if (res.code == 200) {
        if (res.data.childrenInfoFormList.length == 0) {
          this.form.id = res.data.id;
          this.form.parentsName = res.data.parentsName;
          this.form.parentsPhone = res.data.parentsPhone;
          let obj = { id: "", childrenName: "", childrenClass: "" };
          this.form.childrenInfoFormList.push(obj);
          this.editFlag = true;
        } else {
          this.form.id = res.data.id;
          this.form.parentsName = res.data.parentsName;
          this.form.parentsPhone = res.data.parentsPhone;
          this.checked = Boolean(res.data.status);
          this.signUpStatus = Boolean(res.data.todayStatus);
          this.form.childrenInfoFormList = res.data.childrenInfoFormList;
          this.editFlag = false;
        }
      }
    },
    getTime() {
      let date = new Date();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      if (hours == 12 && minutes <= 30) {
        this.disabled = true;
      }
    },
    switchChange(value) {
      if (!this.editFlag) {
        let params = {
          id: this.form.id,
          status: Number(value),
        };
        this.$axios
          .post(`${changeStatusUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code == 200) {
              if (Number(value) == 0) {
                this.$toast("关闭自动报名成功！");
              } else {
                this.$toast("开启自动报名成功！");
              }
            }
          });
      } else {
        console.log(value);
      }
    },
    removeItem(index) {
      this.form.childrenInfoFormList.splice(index, 1);
    },
    addOption() {
      let obj = { id: "", childrenName: "", childrenClass: "" };
      this.form.childrenInfoFormList.push(obj);
    },
  },
};
</script>
<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 22px 30px 116px;
  background: #f6fafa;
  .messageBox {
    padding: 70px 50px;
    box-sizing: border-box;
    position: relative;
    .title {
      font-size: 32px;
      font-weight: 600;
      text-align: center;
      color: rgba(0, 0, 0, 0.85);
      padding-bottom: 42px;
    }
    .content {
      font-size: 28px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 46px;
    }
  }
  .banner {
    width: 100%;
    height: 334px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .main-title-box {
    display: flex;
    align-items: center;
    padding: 34px 0 38px;
    box-sizing: border-box;
    .van-switch {
      border: 10px solid #c7d8ff;
    }
    .title {
      font-size: 28px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      margin-right: 16px;
    }
    .status {
      font-size: 28px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      margin-left: 20px;
    }
    .txt {
      flex: 1;
      text-align: right;
      font-size: 24px;
      font-weight: 600;
      color: #3a75ff;
    }
    img {
      margin-left: 10px;
      width: 24px;
    }
  }
  .signUpBox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .label {
      font-size: 28px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
    .status {
      font-size: 24px;
      margin-left: 16px;
      .blue {
        color: #3a75ff;
      }
      .grey {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .edit-box {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .edit-box-left {
      flex: 1;
      text-align: right;
      font-size: 28px;
      color: #2867ce;
    }
    .edit-box-right {
      width: 46px;
      height: 42px;
      margin-left: 18px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .editInfo {
    .inputBox {
      width: 100%;
      height: 88px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(231, 231, 231, 0.5);
      border-radius: 8px;
      margin-top: 24px;
      display: flex;
      align-items: center;
      position: relative;
      .removeIcon {
        width: 24px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 28px;
      }
      .inputBox-left {
        font-size: 28px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        padding: 0 34px;
        box-sizing: border-box;
      }
      .pr-28 {
        padding-right: 28px;
      }
      .pr-78 {
        padding-right: 78px;
      }
      .inputBox-right {
        flex: 1;
        padding-left: 28px;
        box-sizing: border-box;
        input {
          width: 100%;
          height: 100%;
          display: block;
          text-align: right;
          font-size: 24px;
          color: rgba(0, 0, 0, 0.5);
        }
      }
      .add-left {
        width: 46px;
        height: 46px;
        margin-left: 34px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .add-right {
        margin-left: 18px;
        font-size: 28px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .submitBtn {
      margin-top: 52px;
      height: 96px;
      background: #007eff;
      border-radius: 48px;
      width: 100%;
      text-align: center;
      line-height: 96px;
      font-size: 32px;
      font-weight: 600;
      color: #ffffff;
    }
  }
  .cheackInfo {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(231, 231, 231, 0.5);
    border-radius: 8px;
    padding: 0 28px 0 32px;
    box-sizing: border-box;
    .item {
      width: 100%;
      height: 110px;
      border-bottom: 2px solid rgba(141, 138, 138, 0.1);
      box-sizing: border-box;
      display: flex;
      line-height: 110px;
      .item-left {
        font-size: 28px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        padding-right: 34px;
        box-sizing: border-box;
      }
      .item-right {
        flex: 1;
        text-align: right;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
}
</style>

// 提交申请
const applyUrl = `/gateway/hc-edu/shuttleBusOrder/apply`;
// 获取上次提交信息
const getLastOrderInfoUrl = `/gateway/hc-edu/shuttleBusOrder/getLastOrderInfo`;
// 修改连续报名状态
const changeStatusUrl = `/gateway/hc-edu/shuttleBusOrder/changeStatus`;
// 获取签到信息列表
const getSignInfoUrl = `/gateway/hc-edu/shuttleBusOrder/getSignInfo`;
// 签到
const changeSignUrl = `/gateway/hc-edu/shuttleBusOrder/changeSign`;
export {
  applyUrl,
  getLastOrderInfoUrl,
  changeStatusUrl,
  getSignInfoUrl,
  changeSignUrl,
};
